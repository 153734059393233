/* eslint-disable max-len */
import {Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import MemberHome from '@member/pages/MemberHome';
import {useDispatch} from 'react-redux';
import {BA} from '@util/bachhelpers.js';
import CoursesSearch from '@member/pages/CoursesSearch';
import Exams from '@member/pages/Exams';
import Favorites from '@member/pages/Favorites';
import MyCourses from '@member/pages/MyCourses';
import MyGrades from '@member/pages/MyGrades';
import Profile from '@member/pages/Profile';
import Schedule from '@member/pages/Schedule';
import StudyPlan from '@member/pages/StudyPlan';
import Logout from '@member/pages/Logout';
import Notifications from '@member/pages/Notifications';
import NotificationCenter from '@member/pages/NotificationCenter';
import MyAchievements from '@member/pages/MyAchievements';
import ExternalPage from '@member/pages/ExternalPage';
import ExternDemo from '@member/pages/ExternDemo';
import LVPlanner from '@member/pages/LVPlanner';


function AppMember(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // setTimout 1ms needed, in some cases page didnot scroll up after change
    setTimeout( ()=>{
      window.scrollTo({top: 0, behavior: 'auto'});
    }, 1 );
    if (BA.REFRESHDELAY > 0 && !BA.checkTokenExpired()) {
      sessionStorage.removeItem('bachtoken');
      dispatch({
        type: 'set_auth',
        auth: false,
      });

      dispatch({
        type: 'set_redirect',
        to: '/',
        title: 'Token expired',
        info: `Good bye.<br />See you soon again @myWU.`,
      });

      navigate('/redirect', {replace: false});
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<MemberHome />} />
      <Route path="/favorites" element={<Favorites />} />
      <Route path="/schedule" element={<Schedule />} />
      <Route path="/courses" element={<MyCourses />} />
      <Route path="/courses-search" element={<CoursesSearch />} />
      <Route path="/exams" element={<Exams />} />
      <Route path="/studyplan" element={<StudyPlan />} />
      <Route path="/grades" element={<MyGrades />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/notificationcenter" element={<NotificationCenter />} />
      <Route path="/achievements" element={<MyAchievements />} />

      <Route path="/vortragendenportal" element={<ExternalPage src={process.env.REACT_APP_VORTRAGENDENPORTAL} />} />
      <Route path="/demo1" element={<ExternalPage src="https://bach.wu.ac.at/mywu-dev/extern-demo/vortragendenportal.LOCAL.html" cropped />} />
      <Route path="/demo2" element={<ExternDemo />} />

      <Route path='/lvplanner' element={<LVPlanner />} />

      <Route path="/logout" element={<Logout />} />

      <Route path="*" element={(
        <Navigate to="/" />
      )} />
    </Routes>
  );
}

export default AppMember;
