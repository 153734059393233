/* eslint-disable max-len */
import {BA, useTranslation} from '@util/bachhelpers.js';
import './Calendar.css';
import {Table} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import ScheduleBoxes from '../Boxes/ScheduleBoxes.jsx';
import UI from '@ui/UI';
import {useSelector, useDispatch} from 'react-redux';
import {URL_TYPES} from '../../util/consts';
import PropTypes from 'prop-types';

function CalendarWeek( props ) {
  const {_} = useTranslation();
  const dispatch = useDispatch();

  const weekdays = props.weekdays;
  const weekdaysLong = props.weekdaysLong;

  const selectedDay = useSelector((state) => state.selectedDay);
  const monday = useSelector((state)=>state.selectedMonday);
  const scheduleDates = useSelector((state) => state.scheduleDates);
  const calDate = useSelector((state)=>state.calDateWeek);
  const [xhrload, setXhrload] = useState(false);

  useEffect(()=>{
    setXhrload(true);
    dispatch({
      type: 'set_selected_monday',
      selectedMonday: selectedDay,
    });
    dispatch({
      type: 'set_cal_date_week',
      calDateWeek: {
        weekdays: BA.printWeek( monday, selectedDay ),
      },
    });
    setXhrload(false);
  }, [selectedDay]);

  const changeWeek = (add)=>{
    if ( xhrload ) return false;
    const tempDate = new Date( selectedDay );
    tempDate.setDate( tempDate.getDate() + add * 7);
    dispatch({
      type: 'set_selected_day',
      selectedDay: tempDate.getTime(),
    });
    dispatch({
      type: 'set_cal_date_week',
      calDateWeek: {
        weekdays: BA.printWeek(tempDate.getTime(), tempDate.getTime()),
      },
    });
    dispatch({
      type: 'set_selected_monday',
      selectedMonday: tempDate.getTime(),
    });
  };

  const changeActiveDay = ( e, newDay ) => {
    e.preventDefault();
    if ( xhrload ) return false;

    const tempDate = new Date( newDay );
    dispatch({
      type: 'set_selected_day',
      selectedDay: tempDate.getTime(),
    });
    dispatch({
      type: 'set_cal_date_week',
      calDateWeek: {
        weekdays: BA.printWeek(monday, tempDate.getTime()),
      },
    });
    dispatch({
      type: 'set_selected_monday',
      selectedMonday: tempDate.getTime(),
    });
  };

  const printCurrentDayString = () => {
    return BA.printCurrentDayString(selectedDay, weekdaysLong);
  };

  const loadDates = () => {
    if ( !xhrload ) {
      setXhrload(true);
      BA.ajax({
        bpoapi: true,
        url: BA.buildApiUrl(URL_TYPES.EVENTS_WEEK),
      }).then( async (response) => {
        setXhrload(false);
        dispatch({
          type: 'set_schedule_dates',
          scheduleDates: response.data.events || [],
        });
      }).catch((e)=>{
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      });
    }
  };

  useEffect( ()=> {
    if ((parseInt(selectedDay)!==
        parseInt(sessionStorage.getItem('bach-selectedDay')) ||
        scheduleDates.length===0) ) {
      loadDates();
    }
  }, [selectedDay]);

  const makeCellClass = (active, course, exam) => {
    const classNames = [];
    if ( active ) classNames.push( 'active' );
    return classNames.join(' ');
  };

  const openDateCal = (e)=>{
    // preselected date is not showing on firefox only if use click() instead
    // on chrome only opens with showPicker()
    // showPicker has to fire after click or wont work on firefox
    e.target.querySelector( '.hiddendatefield' ).click();
    setTimeout( ()=>{
      if ( typeof e.target.querySelector( '.hiddendatefield' ).showPicker ===
      'function' ) {
        e.target.querySelector( '.hiddendatefield' ).showPicker();
      }
    }, 50);
  };

  const changeDate = (e)=>{
    changeActiveDay( e, e.target.value );
  };

  return (
    <>
      <div className="calnav">
        <button onClick={()=>{
          changeWeek(-1);
        }}><UI.Icon icon="bi-chevron-left-green" /></button>
        <button onClick={()=>{
          changeWeek(1);
        }}><UI.Icon icon="bi-chevron-right-green" /></button>
        <div className="calnav-text">{_('schedule.week')}</div>
        <div className="calnav-text">{BA.getWeek(new Date(monday))}</div>
      </div>

      <Table className="calendar_week" borderless>
        <tbody>
          <tr>
            {calDate.weekdays.map( (el, i)=>(
              <td className={`text-center weekcell ${
                BA.checkDayHas(
                    'public_holiday',
                    el.date,
                    scheduleDates)?'public_holiday':''
              } ${
                BA.checkDayHas(
                    'recess',
                    el.date,
                    scheduleDates)?'recess':''
              } ${
                BA.isSunday( el.date )?'sunday':''
              }`} key={i}>
                <a
                  href="/"
                  className={makeCellClass(el.active)}
                  onClick={(e)=>{
                    changeActiveDay( e, el.date );
                  }}
                >
                  <span className="small">{weekdays[el.day]}</span><br />
                  <strong>{el.nr}</strong>
                  <span className="dots">
                    {BA.checkDayHas('exam', el.date, scheduleDates) &&
                    <i className="exam appear"></i>}
                    {BA.checkDayHas('course', el.date, scheduleDates) &&
                    <i className="course appear"></i>}
                    {BA.checkDayHas('internal_booking', el.date, scheduleDates) &&
                    <i className="rooms appear"></i>}
                  </span>
                </a>
              </td>
            ))}
          </tr>
        </tbody>
      </Table>


      <div className='day-summary mb-4'>
        {xhrload&&<UI.AjaxLoading />}
        {!xhrload&&
        <div className='appear'>
          <div className="row mt-4 pt-2">
            <h5 className="col-10 col-sm-8">
              <label className="clickabledate" onClick={openDateCal}>
                {printCurrentDayString()}
                <UI.Icon icon="bi-search ms-2" inline nopointer />
                <input
                  type="date"
                  className="hiddendatefield"
                  value={BA.printDateYMD(selectedDay)}
                  onChange={changeDate}
                  onClick={(e)=>{
                    e.stopPropagation();
                  }}
                />
              </label>
            </h5>
            <p className="col-2 col-sm-4 text-end">
              <UI.BachLink className="text-link" href="/member/schedule">
                <UI.Icon icon="bi-calendar2-event bachicon-inline me-2" />
                <span className="d-none d-sm-inline">
                  {_('app.memberhome.show_schedule')}</span>
              </UI.BachLink>
            </p>
          </div>

          <ScheduleBoxes
            currentDay={selectedDay}
            dates={scheduleDates}/>
        </div>}
      </div>

    </>
  );
}
CalendarWeek.propTypes={
  weekdays: PropTypes.array,
  weekdaysLong: PropTypes.array,
};
export default CalendarWeek;
