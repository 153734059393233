import BachTile from './BachTile';
import InfoText from './InfoText';
import './BachTileGroup.css';
import {useSelector} from 'react-redux';
import {useState, useEffect} from 'react';

import {useTranslation} from '@util/bachhelpers.js';
import {LANGUAGES} from '@util/consts';
import PropTypes from 'prop-types';
import {editTile, adminTiles} from '@util/static_tiles';

import {DnD} from '@util/bach_dragndrop.js';


function BachTileGroup( props ) {
  const {_, i18n} = useTranslation();
  const classnames = ['featurebuttons', props.className].join(' ');
  const language = useSelector((state) => state.language);


  const newTiles=props.tiles.map((e)=>({...e}));
  const [myTiles, setMyTiles] = useState( newTiles );

  if ( props.editTile ) {
    useEffect(() => {
      setMyTiles( props.tiles.map((e)=>({...e})) );
      DnD.newTiles = newTiles;
      DnD.saveTileOrder = props.saveTileOrder;
      DnD.setMyTiles = ()=>{
        setMyTiles(DnD.newTiles);
      };
    }, [props.tiles]);


    useEffect(() => {
      DnD.placeholder = document.querySelector(
          '[data-index="'+(DnD.dragIndex+1)+'"]',
      );
    }, [myTiles]);
  }
  /**
   * extra tile at end of all tiles, do open favorites page
   * @param {*} tiles
   * @returns
   */
  const addTiles = (tiles)=>{
    const newtiles = [...tiles];
    if ( props.showedittile ) {
      newtiles.push(...editTile);
    }
    if ( props.showadmintiles ) {
      newtiles.push(...adminTiles);
    }
    return newtiles;
  };

  return (
    <>
      {props.title&&<h2 className="pt-4">{props.title}</h2>}
      <nav className={classnames}>
        <ul className={`row g-${props.gap?props.gap:'2'}`}>
          {addTiles(myTiles).map( (tile, i)=>
           (props.editable||tile.active) ? <BachTile
             {...(!tile.placeholder && props.editTile?{
               onDragStart: DnD.dragStartHandler,
             }:'')}
             key={`tile${i}`}
             title={
              tile.titlekey ? _( tile.titlekey ): (
              tile.title?
                tile.title:
                (language === LANGUAGES.EN? tile.title_en: tile.title_de)
                )}
             href={tile.hreftrans?
                tile.hreftrans.replace(/%language%/, i18n.language):
                tile.href}
             icon={tile.icon}
             variant={tile.variant? tile.variant:''}
             isactive={props.editable && tile.active?true:false}
             isEditable={props.editable}
             index={i}
           >
             {tile.info&&<InfoText>{
              tile.infokey?_(tile.infokey):tile.info
             }</InfoText>}
             {props.editable && !tile.placeholder? (tile.active?
              <button
                className="removeTile"
                onClick={()=>{
                  props.editTile(i, 0);
                }}
              >-</button>:
              <button
                className="addTile"
                onClick={()=>{
                  props.editTile(i, 1);
                }}
              >+</button>):''}
           </BachTile> : '')
          }
        </ul>
      </nav>

    </>
  );
}
BachTileGroup.propTypes={
  editable: PropTypes.bool,
  className: PropTypes.string,
  tiles: PropTypes.array,
  title: PropTypes.string,
  gap: PropTypes.string,
  showedittile: PropTypes.bool,
  showadmintiles: PropTypes.bool,
  editTile: PropTypes.func,
  saveTileOrder: PropTypes.func,
};
export default BachTileGroup;
