/* eslint-disable max-len */
class BachDnD {
  constructor() {
    console.log( 'BachDnd' );


    this.draggingEle;
    this.placeholder;
    this.dragIndex;
    this.newTiles = null;
    this.saveTileOrder = null;
    this.setMyTiles = null;
    this.x = 0;
    this.y = 0;
    this.dragStarted = false;
    this.dragStartHandler = this.dragStart.bind(this);
    this.dragEndHandler = this.dragEnd.bind(this);
    this.dragMoveHandler = this.dragMove.bind(this);
    this.scrollable = true;
    document.addEventListener('touchmove', (e)=>{
      if (this.scrollable===false) {
        e.preventDefault();
      }
    }, {passive: false});
  }

  swap(nodeA, nodeB) {
    // const parentA = nodeA.parentNode;
    const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;
    // Move `nodeA` to before the `nodeB`
    nodeB.parentNode.insertBefore(nodeA, nodeB);
    // Move `nodeB` to before the sibling of `nodeA`
    siblingA.parentNode.insertBefore(nodeB, siblingA);
  };

  isAbove(nodeA, nodeB) {
    const rectA = nodeA.getBoundingClientRect();
    const rectB = nodeB.getBoundingClientRect();
    const middleBY = rectB.top + rectB.height / 2;
    const middleBX = rectB.left + rectB.width / 2;
    return (
      middleBX > rectA.left &&
      middleBY > rectA.top &&
      middleBY < rectA.top + rectA.height) ||
      (
        middleBY > rectA.top + rectA.height &&
        middleBX < rectA.left + rectA.width
      );
  };

  dragStart(e) {
    if ( e.target.tagName !== 'BUTTON' ) {
      // console.log( 'dragstart', e.target);
      this.scrollable = false;
      this.draggingEle = e.target;

      const pX = ( e.touches && e.touches[0] ) ? e.touches[0].pageX : e.pageX;
      const pY = ( e.touches && e.touches[0] ) ? e.touches[0].pageY : e.pageY;

      const rect = this.draggingEle.getBoundingClientRect();
      this.x = pX - rect.left;
      this.y = pY - rect.top + 90;
      this.draggingEle.style.width = rect.width + 'px';


      document.addEventListener( 'mousemove', this.dragMoveHandler );
      document.addEventListener( 'touchmove', this.dragMoveHandler, {passive: false} );

      document.addEventListener( 'mouseup', this.dragEndHandler );
      document.addEventListener( 'touchend', this.dragEndHandler );
    }
  }

  dragEnd(e) {
    // console.log( 'dragend', this.draggingEle );
    this.draggingEle.style.removeProperty('opacity');
    this.draggingEle.style.removeProperty('top');
    this.draggingEle.style.removeProperty('left');
    this.draggingEle.style.removeProperty('position');
    this.draggingEle.style.removeProperty('z-index');
    document.removeEventListener('mousemove', this.dragMoveHandler );
    document.removeEventListener('mouseup', this.dragEndHandler );
    document.removeEventListener('touchmove', this.dragMoveHandler );
    document.removeEventListener('touchend', this.dragEndHandler );


    const changedTiles = [];
    let ranking = 0;

    this.draggingEle.parentNode.querySelectorAll('[data-index]').forEach((el, i)=>{
      if ( el ) {
        const newTile = {...this.newTiles[el.getAttribute('data-index')*1]};
        if ( !newTile.placeholder ) {
          ranking++;
          newTile.ranking = ranking;
          changedTiles.push( newTile );
        }
      }
    });
    this.saveTileOrder(changedTiles);


    this.x = 0;
    this.y = 0;
    this.draggingEle = null;
    this.dragStarted = false;
    this.scrollable = true;
  }

  dragMove(e) {
    e.preventDefault();

    const pX = ( e.touches && e.touches[0] ) ? e.touches[0].pageX : e.pageX;
    const pY = ( e.touches && e.touches[0] ) ? e.touches[0].pageY : e.pageY;


    this.draggingEle.style.position = 'absolute';
    this.draggingEle.style.opacity = 1;
    this.draggingEle.style.left = `${pX - this.x}px`;
    this.draggingEle.style.top = `${pY- this.y + window.scrollY}px`;
    this.draggingEle.style.zIndex = 9999;


    if ( !this.dragStarted ) {
      this.dragStarted = true;
      this.dragIndex = this.draggingEle.getAttribute( 'data-index' )*1;

      this.newTiles.splice(this.dragIndex+1, 0, {
        title: '...',
        active: 0,
        placeholder: true,
        ranking: 9999,
        href: '',
        icon: '',
        variant: 'ghost',
      });
      this.setMyTiles( this.newTiles );
      /*
      setTimeout( ()=>{ // because setMyTiles is asynchron
        this.placeholder = document.querySelector(
            '[data-index="'+(this.dragIndex+1)+'"]',
        );
      }, 250);
      */
    }

    const prevEle = this.draggingEle.previousElementSibling;
    const nextEle = this.placeholder&&this.placeholder.nextElementSibling;

    if (prevEle && this.isAbove(this.draggingEle, prevEle)) {
      if ( this.placeholder ) {
        this.swap(this.placeholder, this.draggingEle);
        this.swap(this.placeholder, prevEle);
      }
      return;
    }
    if (nextEle && this.isAbove(nextEle, this.draggingEle)) {
      this.swap(nextEle, this.placeholder);
      this.swap(nextEle, this.draggingEle);
    }
  }
}


export const DnD = new BachDnD();
