/* eslint-disable max-len */
import UI from '@ui/UI';
import SemesterList from '@ui/SemesterList';
import {Col, Row, Card, Button} from 'react-bootstrap';
import {Search} from 'react-bootstrap-icons';
import {useEffect, useState} from 'react';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {URL_TYPES} from '@util/consts';
import {useSelector, useDispatch} from 'react-redux';
import MyFavorites from '@components/Partials/MyFavorites';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

function LVPlanner() {
  const {_} = useTranslation();
  const dispatch = useDispatch();
  const [xhrload, setXhrload] = useState(false);
  const [searchText, setSearchText] = useState('');
  const currentSemester = useSelector((state) => state.currentSemester);
  // const userInfos = useSelector((state) => state.userInfos);

  // userInfos beinhaltet: tid, afs_username, zuname, vorname
  // const userInfos = {};
  // studies beinhaltet: rueckmeldung, studienplan_skz, studienprogramm, studium, studium_de, studium_eng, study_tid, zulassungscode
  // const studies = [];
  // courseInfos beinhaltet: abmeldung_bis, (abmeldung_von), anmeldeart_de, anmeldeart_en, anmeldung_bis, anmeldung_von, (anmerkung), didaktik, lvtyp_1, sprache_de, sprache_en, tid, titel_de, titel_en, unixepoch_abmeldung_bis, (unixepoch_abmeldung_von), unixepoch_anmeldung_bis, unixepoch_anmeldung_von, (untertitel), verid, wochenstunden_neu
  const [courseInfos, setCourseInfos] = useState({});
  // courseEvents beinhaltet: course_tid, end_time, room_de, room_en, semester, (short_descr), start_time, tid, unixepoch_end_time, unixepoch_start_time
  const [courseEvents, setCourseEvents] = useState([]);
  // courseTeachers beinhaltet: akad_grad_post, akad_grad_prae, course_tid, kurztitel, persid, prp_tid, tid, vorname, zuname
  const [courseTeachers, setCourseTeachers] = useState([]);

  const [savedCourses, setSavedCourses] = useState([]);

  const collisionVerid = [];

  // const userDetail = () => {
  //   if (!currentSemester.id || typeof (currentSemester.id) === 'undefined') {
  //     return;
  //   }
  //   if (!xhrload) {
  //     setXhrload(true);
  //     BA.ajax({
  //       url: BA.buildApiUrl(URL_TYPES.USER_PROFILE),
  //       bpoapi: true,
  //     }).then(async (response) => {
  //       // Objekt -> NICHT verwendet bei base_info: geburtsdatum, praefix_titel, status, suffix_titel
  //       // Objekt -> NICHT verwendet bei bpo (gar NICHT verwendet): avatar, description, employee, roles {notification-admin, mapper-admin}, student, themeId, tid, wuniverse, wuniverseLinked
  //       // Objekt -> NICHT verwendet bei postal_address (gar NICHT verwendet): bezeichnung, bezeichnung_e, code, tid, zustelladresse_ort, zustelladresse_plz, zustelladresse_tel, zustelladresse_wohnhaftbei
  //       // Array -> NICHT verwendet bei studies: /

  //       if (response.data) {
  //         for (const key in response.data.base_info) {
  //           if (['afs_username', 'tid', 'vorname', 'zuname'].includes(key)) {
  //             userInfos[key] = response.data.base_info[key];
  //           }
  //         }
  //         response.data.studies.forEach((element) => {
  //           studies.push(element);
  //         });
  //       }

  //       // dispatch({type: 'set_userInfos', userInfos: userInfos});
  //       setXhrload(false);
  //     }).catch((e)=>{
  //       dispatch({
  //         type: 'set_errcode',
  //         errcode: e,
  //       });
  //       setXhrload(false);
  //     });
  //   }
  // };

  // useEffect(() => {
  //   userDetail();
  // }, []);

  const courseDetail = () => {
    if (!currentSemester.id || typeof (currentSemester.id) === 'undefined') {
      return;
    }
    // const verid = 6088;
    if (!xhrload) {
      console.log(currentSemester.id);
      console.log(searchText);
      setXhrload(true);
      BA.ajax({
        url: BA.buildApiUrl(URL_TYPES.COURSE_DETAILS,
            {id: searchText, semester: currentSemester.id}),
        bpoapi: true,
      }).then(async (response) => {
        // Array -> NICHT verwendet bei course_didaktik (gar NICHT verwendet): tid, bezeichnung, icon
        // Objekt -> NICHT verwendet bei course_info: /
        // Array -> NICHT verwendet bei course_pp (gar NICHT verwendet): fk_planpunkt, planpunkt_de, planpunkt_en
        // Array -> NICHT verwendet bei detail_info (gar NICHT verwendet): kontakt, inhalte_gesamt, ziele_de
        // Array -> NICHT verwendet bei events: /
        // Array -> NICHT verwendet bei teachers: /

        if (response.data) {
          setCourseInfos(response.data.courses.course_info);
          setCourseEvents(response.data.courses.events);
          setCourseTeachers(response.data.courses.teachers);
        }

        // dispatch({type: 'set_userInfos', userInfos: userInfos});
        setXhrload(false);
      }).catch((e)=>{
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      });
    }
  };

  useEffect(() => {
    courseDetail();
  }, []);

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const checkEventCollision = (searchEventsStartTime, searchEventsEndTime) => {
    const searchStartTime = new Date(searchEventsStartTime);
    const searchEndTime = new Date(searchEventsEndTime);

    let collisionFound = false;
    for (const course of savedCourses) {
      for (const courseEvent of course.events) {
        const savedStartTime = new Date(courseEvent.start_time);
        const savedEndTime = new Date(courseEvent.end_time);

        if (searchStartTime.getTime() < savedEndTime.getTime() && searchEndTime.getTime() > savedStartTime.getTime()) {
          if (!collisionVerid.includes(course.verid)) {
            collisionVerid.push(course.verid);
          }
          collisionFound = true;
        }
      }
    }
    console.log(collisionVerid);
    return collisionFound;

    // let collisionLogged = false;

    // for (const course of savedCourses) {
    //   for (const courseEvent of course.events) {
    //     const savedStartTime = new Date(courseEvent.start_time);
    //     const savedEndTime = new Date(courseEvent.end_time);

    //     if (searchStartTime.getTime() < savedEndTime.getTime() && searchEndTime.getTime() > savedStartTime.getTime()) {
    //       if (!collisionLogged) {
    //         console.log('test');
    //         console.log(course.verid);
    //         collisionVerid.push(course.verid);
    //         collisionLogged = true;
    //       }
    //       return true;
    //     }
    //   }
    // }
    // return false;
  };

  const saveCourse = () => {
    let isDuplicate = false;
    const newData = {
      verid: courseInfos.verid,
      titel_de: courseInfos.titel_de,
      titel_en: courseInfos.titel_en,
      teachers: courseTeachers.map((teacher) => ({
        akad_grad_prae: teacher.akad_grad_prae,
        zuname: teacher.zuname,
        vorname: teacher.vorname,
        akad_grad_post: teacher.akad_grad_post,
      })),
      lvtyp: courseInfos.lvtyp_1,
      sprache_de: courseInfos.sprache_de,
      sprache_en: courseInfos.sprache_en,
      didaktik: courseInfos.didaktik,
      anmeldung_von: courseInfos.anmeldung_von,
      anmeldung_bis: courseInfos.anmeldung_bis,
      events: courseEvents.map((event) => ({
        start_time: event.start_time,
        end_time: event.end_time,
        unixepoch_start_time: event.unixepoch_start_time,
        unixepoch_end_time: event.unixepoch_end_time,
      })),
    };
    savedCourses.forEach((element) => {
      if (element.verid === newData.verid) {
        isDuplicate = true;
      }
    });
    if (!isDuplicate) {
      setSavedCourses([...savedCourses, newData]);
    }
  };

  const resetInput = () => {
    setSearchText('');
    setCourseInfos({});
    setCourseEvents([]);
    setCourseTeachers([]);
  };

  const deleteCourse = (course) => {
    // Remove course from savedCourses
    const newSavedCourses = savedCourses.filter((savedCourse) => savedCourse.verid !== course.verid);
    setSavedCourses(newSavedCourses);

    // Remove course.verid from collisionVerid
    const newCollisionVerid = collisionVerid.filter((verid) => verid !== course.verid);
    collisionVerid.push(newCollisionVerid);
  };

  const fullCalendarEvents = [];
  savedCourses.forEach((course) => {
    course.events.forEach((event) => {
      fullCalendarEvents.push({
        title: course.titel_de,
        start: event.start_time,
        end: event.end_time,
      });
    });
  });

  return (
    <Col className="p-0">
      <Row className="px-4 pt-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          <UI.PageTitle backurl="goback">{_('app.lvplanner.title')}</UI.PageTitle>
          <div className=" d-lg-none"><SemesterList/></div>
          <Row className="mt-4">
            <Col >
              <h5 className='pt-3'>Studium Auswahl</h5>
            </Col>
            <Col className="text-end col-6 col-sm-6 col-md-6 col-lg-5 ">
              <UI.StudyList hideHeader={true} />
            </Col>
            <div className='pt-4 pb-4'>
              <UI.InfoBox>
                <UI.InfoText>
                  {_('app.course_search.search_tips')} {_('app.course_search.search_tips_description')}
                </UI.InfoText>
              </UI.InfoBox>
            </div>
            <Card className="card">
              <Card.Header className="card_header p-2">
                {_('app.course_search.search_for_courses')}
              </Card.Header>
              <Card.Body className="card_body py-3">
                <p className="m-0 mb-1">{_('app.course_search.course_information')}</p>
                <input
                  type="text"
                  name="search"
                  placeholder={_('general.search')}
                  id="search_course"
                  className="form-control shadow-sm"
                  onChange={handleChange}
                  // onKeyDown={handleKeyDown}
                  value={searchText}
                  // disabled={xhrload || currentSemester.title === ''}
                />
                <Search
                  id="search-course-magnifier"
                  className="magnifier"
                  onClick={courseDetail}
                  style={{cursor: 'pointer'}}
                />
              </Card.Body>
            </Card>
            <Row className="g-2 my-2 d-flex justify-content-center">
              <Col className="d-grid">
                <Button
                  type="submit"
                  id="submit-input"
                  variant="primary"
                  onClick={courseDetail}
                >
                  {_('app.course_search.search_button')}
                </Button>
              </Col>
              <Col xs={5} sm={4} className="d-grid">
                <Button
                  type="submit"
                  id="reset-input"
                  variant="secondary"
                  onClick={resetInput}
                >
                  {_('app.course_search.reset_button')}
                </Button>
              </Col>
            </Row>
            {xhrload && <UI.AjaxLoading />}
            {(Object.keys(courseInfos).length !== 0 || courseEvents.length !== 0 || courseTeachers.length !== 0) && (
              <div className='card-group mt-5'>
                <div className='card' style={{maxWidth: '30rem'}}>
                  <div className='card-header'>{courseInfos.verid}</div>
                  <div className='card-body'>
                    <h5 className='card-title'>{courseInfos.titel_de}</h5>
                    <h5 className='card-title'>{courseInfos.titel_en}</h5>
                    {courseTeachers.map((teacher, i) => (
                      <p key={i} className='card-text'>{teacher.akad_grad_prae} {teacher.zuname} {teacher.vorname} {teacher.akad_grad_post}</p>
                    ))}
                    <p className='card-text'>{courseInfos.lvtyp_1} ({courseInfos.sprache_de}/{courseInfos.sprache_en}) / {courseInfos.didaktik}</p>
                    <p className='card-text'>Anmeldung von: {courseInfos.anmeldung_von}</p>
                    <p className='card-text'>Anmeldung bis: {courseInfos.anmeldung_bis}</p>
                    {courseEvents.map((events, i) => {
                      const eventCollision = checkEventCollision(events.start_time, events.end_time);
                      return (
                        <div key={i}>
                          {eventCollision ? (
                            <p className='card-text text-danger'>{events.start_time} - {events.end_time}</p>
                          ) : (
                            <p className='card-text'>{events.start_time} - {events.end_time}</p>
                          )}
                        </div>
                      );
                    })}
                    <a className='btn btn-primary mt-3' onClick={saveCourse}>Save Course</a>
                  </div>
                </div>
              </div>
            )}
            <div className='mt-5'>
              {<FullCalendar
                plugins={[dayGridPlugin]}
                initialView='dayGridMonth'
                headerToolbar={{
                  start: 'today prev,next',
                  center: 'title',
                  end: 'dayGridMonth,dayGridWeek,dayGridDay',
                }}
                // events={savedCourses.flatMap((course) =>
                //   course.events.map((event) => ({
                //     title: course.titel_de,
                //     start: event.start_time,
                //     end: event.end_time,
                //   })),
                // )}
                events={fullCalendarEvents}
                displayEventEnd={true}
                eventTimeFormat={{hour: 'numeric', minute: '2-digit', meridiem: false, hour12: false}}
              />}
            </div>
          </Row>
        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3 d-none d-lg-block">
          <MyFavorites showedittile />
          {(Object.keys(savedCourses).length !== 0) && (
            <div className='row row-cols-1'>
              {savedCourses.map((course, i) => (
                <div key={i} className='col mt-3'>
                  <div className={`card ${collisionVerid.includes(course.verid) ? 'border-danger' : ''}`}>
                    <div className='card-header'>{course.verid}</div>
                    <div className='card-body'>
                      <h5 className='card-title'>{course.titel_de}</h5>
                      <h5 className='card-title'>{course.titel_en}</h5>
                      {course.teachers.map((teacher, i) => (
                        <p key={i} className='card-text'>{teacher.akad_grad_prae} {teacher.zuname} {teacher.vorname} {teacher.akad_grad_post}</p>
                      ))}
                      <a className="btn btn-primary" onClick={() => deleteCourse(course)}>Delete Course</a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Row>
    </Col>
  );
}

export default LVPlanner;
