/* eslint-disable max-len */
import Modal from 'react-bootstrap/Modal';
import {useTranslation} from '@util/bachhelpers.js';
import {useEffect, useState} from 'react';
import './BrowserDetector.css';

const BrowserDetector = () => {
  const {_} = useTranslation();

  const [isRunOnce, setIsRunOnce] = useState(false);

  const [show, setShow] = useState(false);
  const [browser, setBrowser] = useState('');
  const [browserVersion, setBrowserVersion] = useState('');
  const [browserMajorVersion, setBrowserMajorVersion] = useState(-1);
  const [isBrowserUnsupported, setIsBrowserUnsupported] = useState(false);
  const [isBrowserOutdated, setIsBrowserOutdated] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {userAgent} = navigator;
  const supportMatrix = {
    Firefox: {name: 'Mozilla Firefox', shortName: 'Firefox', version: 90},
    Edge: {name: 'Microsoft Edge', shortName: 'Edge', version: 79},
    Chrome: {name: 'Google Chrome', shortName: 'Chrome', version: 70},
    Safari: {name: 'Safari', shortName: 'Safari', version: 15},
  };

  const determineBrowser = () => {
    let version ='';
    let browser = {};
    // ref https://developers.whatismybrowser.com/useragents/explore/software_name/safari/
    if (userAgent.includes('Firefox/')) {
      browser =supportMatrix.Firefox;
      version=userAgent.split('Firefox/')[1];
    } else if (userAgent.includes('Edg/')) {
      browser =supportMatrix.Edge;
      version=userAgent.split('Edg/')[1];
    } else if (userAgent.includes('Chrome/')) {
      browser =supportMatrix.Chrome;
      version=userAgent.split('Chrome/')[1].split('Safari/')[0];
    } else if (userAgent.includes('Safari/') &&
     !userAgent.includes('Chrome/')) {
      browser =supportMatrix.Safari;
      version=userAgent.split('Safari/')[1];
    } else {
      setIsBrowserUnsupported(true);
      return;
    }

    setBrowser(browser);
    setBrowserVersion(version);
    setBrowserMajorVersion(parseInt(version.split('.')[0]));
  };

  useEffect(()=>{
    if (browser.version && browserMajorVersion!==-1) {
        browserMajorVersion >= supportMatrix[browser.shortName].version?
        setIsBrowserOutdated(false):setIsBrowserOutdated(true);
    }
  }, [browser.version, browserMajorVersion]);

  useEffect(()=>{
    if (!isRunOnce) {
      setIsRunOnce(true);
      determineBrowser();
    }
  }, [isRunOnce]);


  useEffect(() => {
        (isBrowserOutdated||isBrowserUnsupported)?handleShow():handleClose();
  }, [isBrowserOutdated, isBrowserUnsupported]);

  return (
    <>
      <Modal data-testid='browser-detector-modal'
        show={show}
        onHide={handleClose}
        onClick={handleClose}>
        <Modal.Header className="bd-modal-header" closeButton>
          <Modal.Title data-testid='browser-detector-modal-title'>
            {_('app.browser_detection.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`text-center bd-modal-body`}>
          <h5>{_('app.browser_detection.subtitle')}</h5>
          {browser.shortName &&
          <span data-testid='browser-detector-browser-info'>
            {_('app.browser_detection.your_browser')} {browser.shortName} {browserVersion}
          </span>}
          <div className={`bi-browser bd-icon`}/>
          <p data-testid='browser-detector-supported-browsers'
            className={`col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 
            bd-main-text`}>
            {_('app.browser_detection.text_unsupported_browser')}
            {Object.values(supportMatrix).map((browser, i)=>
              (
                <span data-testid='browser-detector-supported-browser'
                  key={i}
                  className={`browser`}>
                  {(i===Object.values(supportMatrix).length-1) &&
                    <span> {_('app.browser_detection.or')}</span>}
                  <span key={browser.shortName}
                    className={`browser-name ms-1`}>
                    {browser.name}
                  </span>
                </span>
              ))
            }
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BrowserDetector;
