import {BA, useTranslation} from '@util/bachhelpers.js';
import './ScheduleBoxes.css';
import Box from './Box';
import {URL_TYPES} from '@util/consts.js';
import PropTypes from 'prop-types';
import UI from '@ui/UI';

function ScheduleBoxes( props ) {
  const {_, i18n} = useTranslation();


  // remove hidden dates
  const filteredDates = props.dates.filter( (el)=>{
    const currentDay = (new Date(props.currentDay)).toDateString();

    // fix for iOS, not working with format 'YYYY-MM-DD HH:ii'
    // need 'YYYY-MM-DDTHH:ii'
    const startdate = el.start.replace(' ', 'T');
    const enddate = el.end.replace(' ', 'T');

    const eventStartDay = new Date(startdate).toDateString();
    const eventEndDay = new Date(enddate).toDateString();

    return (new Date(eventStartDay)).getTime()<=
     (new Date(currentDay)).getTime() &&
     (new Date(eventEndDay)).getTime()>=(new Date(currentDay)).getTime();
  });


  return (
    <div className="schedule-boxes">
      {props.dates && filteredDates.map( (el, i)=>{
        return (<Box
          key={i}
          type={ el.event_type}
          href={
            el.event_type === 'course' ?
              BA.buildUIRouteUrl(URL_TYPES.COURSE_DETAILS,
                  {id: el.verid, semester: el.semester}) :
              el.event_type === 'exam' ?
              BA.buildApiUrl(URL_TYPES.EXAMS, {semester: el.semester}) :
              el.event_type === 'internal_booking' ?
              el.event_link :
              ''
          }>
          <div className="col-12">
            <strong>{
              el['titel_'+i18n.language] !== null ?
              el['titel_'+i18n.language] : el['titel_de']
            }</strong>
            <div>
              {_(`schedule.${el.event_type}`)}
            </div>
          </div>
          {el.event_type!='public_holiday'&&el.event_type!='recess'&&<>
            <div className="col-5">
              <UI.Icon icon="bi-clock"
                inline></UI.Icon> {BA.getTimeString( el.start)} - {
                BA.getTimeString( el.end)}
            </div>
            <div className="col-7">
              <UI.Icon icon="bi-location" inline></UI.Icon>
              {el['room_'+i18n.language]}
            </div></>}
        </Box>);
      })}

      { filteredDates.length === 0 &&
      (<div className="p-2 mb-2">{_('schedule.no_courses_today')}</div>)}

    </div>
  );
}
ScheduleBoxes.propTypes={
  dates: PropTypes.array,
  currentDay: PropTypes.number,
};
export default ScheduleBoxes;
