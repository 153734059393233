/* eslint-disable max-len */
import {BA, useTranslation} from '@util/bachhelpers.js';
import './Calendar.css';
import {Table} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import ScheduleBoxes from '../Boxes/ScheduleBoxes.jsx';
import UI from '@ui/UI';
import {useSelector, useDispatch} from 'react-redux';
import {URL_TYPES} from '@util/consts';
import PropTypes from 'prop-types';


function CalendarMonth(props) {
  const {_} = useTranslation();
  const weekdays = props.weekdays;
  const weekdaysLong = props.weekdaysLong;

  const monthNames = [
    _('schedule.january'),
    _('schedule.february'),
    _('schedule.march'),
    _('schedule.april'),
    _('schedule.may'),
    _('schedule.june'),
    _('schedule.july'),
    _('schedule.august'),
    _('schedule.september'),
    _('schedule.october'),
    _('schedule.november'),
    _('schedule.december')];

  const selectedDay = useSelector((state) => state.selectedDay);
  const scheduleDates = useSelector((state) => state.scheduleDates);
  const monday = useSelector((state)=>state.firstMondayOfMonth);
  const firstOfMonth = useSelector((state)=>state.firstOfMonth);
  const calDate = useSelector((state)=>state.calDateMonth);
  const dispatch = useDispatch();

  useEffect(()=>{
    const firstOfMonthTemp = new Date(Date.UTC(new Date(selectedDay).getFullYear(),
        new Date(selectedDay).getMonth(), 1)).getTime();

    dispatch({
      type: 'set_first_of_month',
      firstOfMonth: firstOfMonthTemp,
    });
    dispatch({
      type: 'set_first_monday_of_month',
      firstMondayOfMonth: selectedDay,
    });
    dispatch({
      type: 'set_cal_date_month',
      calDateMonth: {
        days: BA.printMonth(BA.getMondayOfCurrentWeek(firstOfMonthTemp), selectedDay),
        month: `${monthNames[(new Date(selectedDay)).getMonth()]} ${(new Date(selectedDay)).getFullYear()}`,
      },
    });
  }, [selectedDay]);

  const [xhrload, setXhrload] = useState(false);


  const changeMonth = (add) => {
    if (xhrload) return false;

    const tempDate = new Date(firstOfMonth);
    tempDate.setMonth(tempDate.getMonth() + add);
    dispatch({
      type: 'set_selected_day',
      selectedDay: tempDate.getTime(),
    });
  };

  const changeActiveDay = (e, newDay) => {
    e.preventDefault();
    if (xhrload) return false;

    /**
    * change month if selected day is in different month!!
    */
    const tempDate = new Date(firstOfMonth);
    if ((new Date(selectedDay)).getMonth() !== (new Date(newDay)).getMonth()) {
      tempDate.setMonth((new Date(newDay)).getMonth());
    }

    dispatch({
      type: 'set_selected_day',
      selectedDay: newDay,
    });


    dispatch({
      type: 'set_selected_monday',
      selectedMonday: newDay,
    });
  };

  const printCurrentDayString = () => {
    const currentDay = new Date(selectedDay);
    // Sunday fix
    let day = currentDay.getDay() - 1;
    if (day === -1) {
      day = 6;
    };

    return `${weekdaysLong[day]}, ${currentDay.getDate()}.${currentDay.getMonth() + 1}.${currentDay.getFullYear()}`;
  };


  const loadDates = () => {
    if (!xhrload) {
      setXhrload(true);
      BA.ajax({
        bpoapi: true,
        url: BA.buildApiUrl(URL_TYPES.EVENTS_MONTH),
      }).then(async (response) => {
        setXhrload(false);
        // console.log('set dates month');
        dispatch({
          type: 'set_schedule_dates',
          scheduleDates: response.data.events || [],
        });
        // console.log(response);
      }).catch((e)=>{
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      });
    }
  };

  const openDateCal = (e)=>{
    e.target.querySelector( '.hiddendatefield' ).click();
    setTimeout( ()=>{
      if ( typeof e.target.querySelector( '.hiddendatefield' ).showPicker ===
        'function' ) {
        e.target.querySelector( '.hiddendatefield' ).showPicker();
      }
    }, 50);
  };

  const changeDate = (e)=>{
    changeActiveDay( e, e.target.value );
  };

  useEffect(() => {
    if (parseInt(selectedDay)!==
        parseInt(sessionStorage.getItem('bach-selectedDay')) ||
        scheduleDates.length===0) {
      loadDates();
    }
  }, [monday]);

  const makeCellClass = (active, course, exam) => {
    const classNames = [];
    if (active) classNames.push('active');
    return classNames.join(' ');
  };

  return (
    <>
      <div className="calnav">
        <b>{calDate.month}</b>
        <button onClick={() => {
          changeMonth(-1);
        }}><span className="bachicon bi-chevron-left-green"></span></button>
        <button onClick={() => {
          changeMonth(1);
        }}><span className="bachicon bi-chevron-right-green"></span></button>
      </div>

      <Table className="calendar_month" borderless>
        <tbody>
          <tr>{
            [0, 1, 2, 3, 4, 5, 6].map(
                (el, i) => (
                  <td className="text-center weekcell appear" key={i}>
                    <span className="small">{weekdays[el]}</span>
                  </td>
                ),
            )}
          </tr>
          {calDate.days.map((week, j) => (
            <tr key={j}>
              {week.map((el, i) => {
                // console.log( 'week.map', i, Date.now() );
                return (
                  <td className={`text-center weekcell monthcell ${
                    BA.checkDayHas('public_holiday', el.date, scheduleDates)?'public_holiday':''
                  } ${
                    BA.checkDayHas('recess', el.date, scheduleDates)?'recess':''
                  } ${
                    BA.isSunday(el.date)?'sunday':''
                  }`} key={i}>
                    <a
                      href="/"
                      className={makeCellClass(el.active)}
                      onClick={(e) => {
                        changeActiveDay(e, el.date);
                      }}
                    >

                      <strong>{el.nr}</strong>
                      <span className="dots">
                        {BA.checkDayHas('exam', el.date, scheduleDates) && <i className="exam appear"></i>}
                        {BA.checkDayHas('course', el.date, scheduleDates) && <i className="course appear"></i>}
                        {BA.checkDayHas('internal_booking', el.date, scheduleDates) && <i className="rooms appear"></i>}
                      </span>
                    </a>
                  </td>
                );
              },
              )}
            </tr>
          ))}
        </tbody>
      </Table>


      {xhrload && <UI.AjaxLoading />}
      {!xhrload &&
      <div className="appear">
        <h5 className="mt-4 pt-2">
          <label className="clickabledate" onClick={openDateCal}>
            {printCurrentDayString()}
            <UI.Icon icon="bi-search ms-2" inline nopointer />
            <input
              type="date"
              className="hiddendatefield"
              value={BA.printDateYMD(selectedDay)}
              onChange={changeDate}
              onClick={(e)=>{
                e.stopPropagation();
              }}
            />
          </label>
        </h5>
        <ScheduleBoxes currentDay={selectedDay} dates={scheduleDates} />
      </div>}

    </>
  );
}
CalendarMonth.propTypes={
  weekdays: PropTypes.array,
  weekdaysLong: PropTypes.array,
};
export default CalendarMonth;
