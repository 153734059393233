/* eslint-disable camelcase */
import {useState, useEffect, useRef} from 'react';
import {Navbar, Container} from 'react-bootstrap';
import './Header.css';
import MainMenu from '../Navigation/MainMenu';
import UI from '@ui/UI';
import HeaderPart from './HeaderPart';
import WULogo from './WULogo';
import BACHLogo from './BACHLogo';
import {useSelector, useDispatch} from 'react-redux';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {useNavigate} from 'react-router-dom';
import * as consts from '@util/consts.js';
import {LANGUAGES, URL_TYPES, DEBUGMODE} from '@util/consts';

function Header(props) {
  const [showMenu, setShowMenu] = useState(false);
  const {_, i18n} = useTranslation();
  const [user, setUser] = useState({});
  const [expireCounter, setExpireCounter] = useState(0);
  const navigate = useNavigate();
  const spanElement = useRef(null);

  const inactive = useRef(0);
  const inactivityElement = useRef(null);

  const handleClose = () => setShowMenu(false);
  const handleShow = () => setShowMenu(true);

  const language = useSelector((state) => state.language);
  const auth = useSelector((state) => state.auth);
  const userInfo = useSelector((state) => state.userInfo);
  const semesters = useSelector((state) => state.semesters);
  const baseInfo = useSelector((state) => state.profileBaseInfo);
  const dispatch = useDispatch();
  const mappingInfo = useSelector((state) => state.mappingInfo);


  /**
   * load language from session
   */
  const language_session = sessionStorage.getItem( 'bach-language' );
  useEffect(() => {
    if ( language_session && typeof language_session !== 'undefined' ) {
      const newLang = language_session.toLowerCase() === LANGUAGES.EN ?
      LANGUAGES.EN : LANGUAGES.DE;
      i18n.changeLanguage(newLang);
      dispatch({
        type: 'set_language',
        language: newLang,
      });
    }
  }, [language_session]);

  const handleChangeLang = () => {
    const newLang = language === LANGUAGES.DE ? LANGUAGES.EN : LANGUAGES.DE;
    i18n.changeLanguage(newLang);
    dispatch({
      type: 'set_language',
      language: newLang,
    });
  };
  useEffect(() => {
    if (auth) {
      const user = BA.getTokenInfo();
      setExpireCounter(Math.floor(user.exp - Date.now() / 1000));
      setUser(user);
    } else {
      setUser({});
    }
  }, [auth]);

  useEffect(() => {
    console.log('userAuth: ' + auth);
    if (auth) {
      /**
       * Load userInfo
       */
      BA.ajax({
        url: BA.buildApiUrl(URL_TYPES.USER_INFO),
        bpoapi: true,
      }).then(async (response) => {
        // Double check,
        // prevent the cases that auth is changed while a call has dispatched
        console.debug('Fetch user info callback', response);
        if (auth) {
          dispatch({
            type: 'set_userinfo',
            student: response.data.userinfo.student,
            wuniverseLinked: response.data.userinfo.wuniverseLinked,
            // eslint-disable-next-line max-len
            avatar: (response.data.userinfo.avatar || response.data.userinfo.avatar !== null) ?
              // eslint-disable-next-line max-len
              process.env.REACT_APP_BPOAPISERVER + '/' + response.data.userinfo.avatar :
              null,
            employee: response.data.userinfo.employee,
            wuniverse: response.data.userinfo.wuniverse,
            description: response.data.userinfo.description,
            roles: response.data.userinfo.roles,
            tid: response.data.userinfo.tid,

          });
          // eslint-disable-next-line max-len
          console.log('Avatar URL:', process.env.REACT_APP_BPOAPISERVER + '/' + response.data.userinfo.avatar);
          dispatch({
            type: 'set_theme',
            profile_colormode: response.data.userinfo.themeId,
          });
        } else {
          dispatch({
            type: 'set_userinfo',
            student: null,
            wuniverseLinked: null,
            avatar: null,
            employee: null,
            wuniverse: null,
            description: null,
            roles: [],
            tid: null,
          });
          dispatch({
            type: 'set_theme',
            profile_colormode: consts.THEME_STANDARD,
          });
        }
      }).catch((e) => {
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        console.log('Error Loading user info', e);
      });
    }
  }, [auth]);


  useEffect(() => {
    let xhrRefresh = false;
    const int = setInterval(() => {
      if (spanElement.current) {
        const exp = Math.floor(user.exp - Date.now() / 1000);
        if ( DEBUGMODE ) {
          spanElement.current.innerHTML = exp;
        }
        outputInactivity();
        /**
         * ask for RefreshToken (only once!)
         */
        if (BA.REFRESHDELAY > 0 && exp <= BA.REFRESHDELAY && !xhrRefresh) {
          xhrRefresh = true;
          console.log('xhrRefresh');

          BA.ajax({
            url: '/idpapi/refresh-token',
            method: 'GET',
            bpoapi: false,
          }).then(async (response) => {
            const user = BA.getTokenInfo(response);
            console.log('getTokenInfo', user);
            if (user.exp && Math.floor(user.exp - Date.now() / 1000) >
            BA.REFRESHDELAY) {
              console.log('new access token expires after actual token');
              sessionStorage.setItem('bachtoken', response);
              setUser(user);
            } else {
              console.log('refreshed token expires before REFRESHDELAY');
            }
          }).catch( (e) => {
            dispatch({
              type: 'set_errcode',
              errcode: e,
            });
            console.log( 'Error Loading refresh token', e );
          });
        }

        /**
         * Token expired, redirect to /
         * Token will be removed from sessionStorage and
         * User gets Logout Redirect
         */
        if (BA.REFRESHDELAY > 0 && exp <= 0) {
          if ( DEBUGMODE ) {
            spanElement.current.innerHTML = 0;
          }
          // sessionStorage.removeItem('bachtoken');
          clearInterval(int);
          setTimeout(() => {
            navigate('/logoutapp', {replace: false});
          }, 1000);
        }
      }
    }, 500);
    return () => clearInterval(int);
  }, [user]);

  useEffect(() => {
    if ( semesters.length===0) {
      BA.ajax({
        bpoapi: true,
        url: BA.buildApiUrl(URL_TYPES.SEMESTERS),
      }).then( async (response) => {
        if (response.data.semesters && response.data.semesters.length > 0) {
          const semesters = await response.data.semesters.map((semester) => (
            {
              id: parseInt(semester.tid),
              shortTitle: semester.kurzbezeichnung_vm,
              title: semester.sem_bz_de,
            }
          ));
          dispatch({
            type: 'set_semesters',
            semesters: semesters,
          });
          dispatch({
            type: 'set_current_semester',
            currentSemester: semesters[0],
          });
        }
      }).catch( (e) => {
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        console.log( 'Error Loading semesters', e );
      });
    }
  }, [semesters]);


  useEffect(()=>{
    if (!auth) {
      return;
    }

    /**
     * example with abort ajax call on leaving page
    */
    let controller = {signal: false};
    if ( window.AbortController ) {
      controller = new AbortController();
    }
    // only load data if it was not load yet
    if (baseInfo.loginName!=null) {
      return;
    }
    BA.ajax({
      url: BA.buildApiUrl(URL_TYPES.USER_PROFILE),
      bpoapi: true,
      abort: controller.signal,
    }).then(async (response) => {
      // studies
      const studies = response.data.studies?response.data.studies.map(
          ({studium, studium_de, studium_en, study_tid, zulassungscode,
            rueckmeldung})=>(
            {
              id: study_tid,
              studyCode: studium,
              nameEn: studium_en,
              nameDe: studium_de,
              admissionCode: zulassungscode,
              rueckmeldung: rueckmeldung,
            }
          )):[];

      dispatch({
        type: 'set_studies',
        studies: studies,
      });

      // Prepare studyPrograms for dispatch
      const studyPrograms = [];

      if (response.data.studies) {
        response.data.studies
        // Deconstruct and filter study programs with code
            .map((sp) => {
              const {studienplan_skz: code,
                studienprogramm: abbreviation,
                studium_de: nameDe,
                studium_en: nameEn} = sp;

              if (code) {
                return {code, nameDe, nameEn, abbreviation};
              }
            })
        // Remove duplicates
            .map((el) => {
              // case: no study programs
              if (el === undefined) return;
              // eslint-disable-next-line max-len
              if (studyPrograms.filter((sp) => sp.code === el.code).length === 0) {
                studyPrograms.push(el);
              };
            });
      }
      // Sort alphabetically by abbreviation
      if (studyPrograms.length > 0) {
        studyPrograms.sort((a, b) => {
          const abbA = a.abbreviation.toUpperCase();
          const abbB = b.abbreviation.toUpperCase();
          if (abbA < abbB) {
            return -1;
          }
          if (abbA > abbB) {
            return 1;
          }
          return 0;
        });
      }

      dispatch({
        type: 'set_study_programs',
        studyPrograms: studyPrograms,
      });

      // basic info
      const profileBaseInfo = {
        id: response.data.base_info.tid,
        loginName: response.data.base_info.afs_username,
        firstName: response.data.base_info.vorname,
        lastName: response.data.base_info.zuname,
        prefixTitle: response.data.base_info.praefix_titel,
        suffixTitle: response.data.base_info.suffix_titel,
        dob: response.data.base_info.geburtsdatum,
      };
      dispatch({
        type: 'set_profile_base_info',
        profileBaseInfo: profileBaseInfo,
      });
      // account info
      dispatch({
        type: 'set_profile_account_info',
        profileAccountInfo: {
          telNr: null,
          email: null,
          password: null,
          wlanPassword: null},

      });
      // personal data
      let profilePersonalData;
      if ( response.data.postal_address ) {
        profilePersonalData = {
          address: response.data.postal_address.zustelladresse_str,
          co: response.data.postal_address.zustelladresse_wohnhaftbei?
            response.data.postal_address.zustelladresse_wohnhaftbei:'',
          city: response.data.postal_address.zustelladresse_ort,
          postCode: response.data.postal_address.zustelladresse_plz,
          countryId: response.data.postal_address.tid,
          countryCode: response.data.postal_address.code,
          countryName: response.data.postal_address.bezeichnung,
          countryNameEn: response.data.postal_address.bezeichnung_e,
          telNr: response.data.postal_address.zustelladresse_tel?
            response.data.postal_address.zustelladresse_tel:0,
        };
      } else {
        profilePersonalData = {
          address: '',
          co: '',
          city: '',
          postCode: '',
          countryId: '',
          countryCode: '',
          countryName: '',
          countryNameEn: '',
          telNr: 0,
        };
      }


      dispatch({
        type: 'set_profile_personal_data',
        profilePersonalData: profilePersonalData,
      });
    }).catch( (e) => {
      if ( isFinite( e ) ) {
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
      }
      console.log('Error Loading user profile', e);
    });
    return () => {
      if ( controller.signal ) {
        console.log('aborted');
        controller.abort();
      }
    };
  }, [auth]);

  /**
     * Inactivity Checker
     */

  const outputInactivity = () => {
    const inactiveSeconds = Math.floor((Date.now() - inactive.current) / 1000);
    const logoutInSec = 60 * BA.INACTIVITYLOGOUT_MINUTES - inactiveSeconds;

    if ( DEBUGMODE ) {
      inactivityElement.current.innerHTML = inactiveSeconds > 2 ?
     _('app.logout.inactivity_timer', Math.max(0, logoutInSec)) : '';
    }
    if (inactiveSeconds > 60 * BA.INACTIVITYLOGOUT_MINUTES) {
      goBackToHome();
    }
  };

  const goBackToHome = () => {
    // code to reset the application
    // console.log('Logout due to inacticity');
    navigate('/logoutinactive', {replace: false});
  };


  const onMouseMove = () => {
    restartAutoReset();
  };

  const restartAutoReset = () => {
    inactive.current = Date.now();
  };

  useEffect(() => {
    if (auth) {
      restartAutoReset();
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('keyup', onMouseMove);
      window.addEventListener('touchstart', onMouseMove);
      return () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('keyup', onMouseMove);
        window.removeEventListener('touchstart', onMouseMove);
      };
    }
  }, [auth]);

  useEffect(()=> {
    const newMappingInfo = {};
    if (BA.hasPermission('mapper-admin', userInfo) && userInfo.roles) {
      newMappingInfo.isMappingVisible = true;
      // eslint-disable-next-line max-len
      newMappingInfo.isUserMapped = ['login_mapping_student', 'login_mapping_employee'].includes(userInfo.description);
    } else {
      newMappingInfo.isMappingVisible = false;
      newMappingInfo.isUserMapped = false;
    }
    console.debug('New Mapping Info:', newMappingInfo);
    dispatch({
      type: 'set_mapping_info',
      mappingInfo: newMappingInfo,
    });
  }, [userInfo]);

  // load notification types and groups
  useEffect(()=> {
    if (!auth) {
      return;
    }
    // types needed for all users
    BA.ajax({
      url: BA.buildApiUrl(URL_TYPES.NOTIFICATION_TYPE),
      bpoapi: true,
    }).then(async (response) => {
      if (response.data.notification_type_list &&
          response.data.notification_type_list.length > 0) {
        // eslint-disable-next-line max-len
        const not_types = await response.data.notification_type_list.map((not_type) => (
          {
            id: not_type.tid,
            titel_de: not_type.titel_de,
            titel_en: not_type.titel_en,
            description_de: not_type.description_de,
            description_en: not_type.description_en,
            icon: not_type.icon,
          }
        ));
          // console.log('Notification Types:', not_types);
        dispatch({
          type: 'set_notification_types',
          notificationTypes: not_types,
        });
      }
    }).catch( (e) => {
      if ( isFinite( e ) ) {
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
      }
      console.log('Error Loading notification types', e);
    });

    if ( BA.hasPermission( 'notification-admin', userInfo) ) {
      BA.ajax({
        url: BA.buildApiUrl(URL_TYPES.NOTIFICATION_GROUP),
        bpoapi: true,
      }).then(async (response) => {
        if (response.data.notification_group_list &&
            response.data.notification_group_list.length > 0) {
          // eslint-disable-next-line max-len
          const not_groups = await response.data.notification_group_list.map((not_group) => (
            {
              id: not_group.tid,
              titel_de: not_group.titel_de,
              titel_en: not_group.titel_en,
            }
          ));
            // console.log('Notification Groups:', not_groups);
          dispatch({
            type: 'set_notification_groups',
            notificationGroups: not_groups,
          });
        }
      }).catch( (e) => {
        if ( isFinite( e ) ) {
          dispatch({
            type: 'set_errcode',
            errcode: e,
          });
        }
        console.log('Error Loading notification groups', e);
      });
    }
  }, [userInfo]);

  return (
    <>
      <MainMenu showMenu={showMenu} handleClose={handleClose} auth={auth} />
      <header id="mainhead">
        <Navbar>
          <Container fluid>
            <div className="flex-fill">
              <div className="d-flex align-items-center p-1">
                <HeaderPart className="p-1 logo_wu_cropped">
                  <div className="logo_wu_wrapper">
                    <WULogo />
                  </div>
                </HeaderPart>
                <HeaderPart className="pe-2">
                  <UI.BachLink
                    href={auth ?'/member/profile' : BA.BACHURL+'/idpapi/login'}
                    id="profile">
                    <UI.ImageElement
                      cover
                      src={userInfo.avatar}
                      alt="userimage" />
                  </UI.BachLink>
                </HeaderPart>
                <HeaderPart className="flex-grow-1">
                  <BACHLogo />
                  {auth ?
                    <><div>
                      <span className="truncate">
                        {user.username || user.preferred_username}
                        {mappingInfo.isMappingVisible &&
                         mappingInfo.isUserMapped && ( <>
                            /{baseInfo.loginName}
                        </>
                        )}
                      </span>
                      {mappingInfo.isMappingVisible && (
                        <span className="d-none d-md-inline"> | <UI.BachLink
                          href="member/profile">
                          {_('app.header.map_change')}</UI.BachLink>
                        </span>)}
                      <span className="d-none d-md-inline"> | <UI.BachLink
                        href="member/logout">
                        {_('app.header.logout')}</UI.BachLink>
                      </span>
                    </div>
                    {DEBUGMODE&&<><div className="debugmodeinfo">DEBUG:{
                      process.env.REACT_APP_ENV
                    }</div>
                    <div className="tokenexpires">
                      <div>
                        {_('app.tokenexpires')} <span ref={spanElement}>
                          {expireCounter}</span>s
                      </div>
                      <div>
                        <span ref={inactivityElement}></span>
                      </div>
                    </div>
                    </>}
                    </> :
                    <>

                      <div>{_('app.header.notloggedin')}</div>
                      {DEBUGMODE&&
                      <div className="debugmodeinfo">DEBUG:{
                        process.env.REACT_APP_ENV
                      }</div>}
                    </>
                  }
                </HeaderPart>
                <HeaderPart>
                  <a href="#"
                    onClick={handleShow}
                    id="menu_opener"><hr /><hr /><hr />
                  </a>
                </HeaderPart>
                <HeaderPart>
                  <UI.BachLink href="#"
                    id="change_language"
                    clickHandler={handleChangeLang}>
                    {(language === LANGUAGES.DE ? LANGUAGES.EN : 'DE')
                        .toUpperCase()}
                  </UI.BachLink>
                </HeaderPart>
              </div>
            </div>

          </Container>
        </Navbar>
      </header>
    </>
  );
}
export default Header;
